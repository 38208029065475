<template>
  <v-app>
    <page-view />
  </v-app>
</template>

<script>
  export default {
    name: 'UsersLayout',

    components: { PageView: () => import('./View') },
  }
</script>
